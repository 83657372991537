<template>
  <div class="slider-controls" role="group" :aria-label="ariaLabel">
    <v-btn
        :icon="[prevIcon]"
        :color="buttonColor"
        small
        :class="{ 'is-active': activeButton === 'prev' }"
        class="slider-control-btn"
        @click="onPrevious"
        :title="ariaLabelPrev"
    />
    <v-btn
        :icon="[toggleIcon]"
        :color="buttonColor"
        small
        :class="{
        'is-active': activeButton === 'toggle',
      }"
        @click="onToggle"
        class="slider-control-btn"
        :title="ariaLabelToggle"
    />
    <v-btn
        :icon="[nextIcon]"
        :color="buttonColor"
        small
        :class="{ 'is-active': activeButton === 'next' }"
        class="slider-control-btn"
        @click="onNext"
        :title="ariaLabelNext"
    />
  </div>
</template>

<script setup lang="ts">
import {mdiChevronLeft, mdiChevronRight, mdiPause, mdiPlay} from "@mdi/js";

interface Props {
  isPaused: boolean;
  isSliderHovered: boolean;
  activeButton: string;
  onNext: () => void;
  onPrevious: () => void;
  onToggle: () => void;
  ariaLabel: string;
  ariaLabelPrev: string;
  ariaLabelNext: string;
  ariaLabelToggle: string;
  buttonColor?: string;
}

const props = withDefaults(defineProps<Props>(), {
  buttonColor: "primary",
});

const prevIcon = mdiChevronLeft;
const nextIcon = mdiChevronRight;
const toggleIcon = computed(() => (props.isPaused ? mdiPlay : mdiPause));
</script>

<style lang="scss">
.slider-controls {
  z-index: 10;
  display: flex;
  gap: 8px;
  opacity: 0.7;
  transition: opacity 0.3s ease;

  .slider-control-btn.v-btn {
    --v-hover-opacity: 0;
    transition: transform 0.1s ease-in-out,
    background-color 0.1s ease-in-out;

    &.is-active {
      transform: scale(0.95);
      background-color: rgba(var(--v-theme-primary-darken-2), 0.1);
    }
  }
}
</style>
